import React from 'react'

const Festivals = () => {
    return (
        <div>
        <div className="bg-white">
            <div
                className="relative isolate overflow-hidden bg-orange-700 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
                <h2 className="text-lg font-bold tracking-tight text-white sm:text-4xl">
                    Be a sponsor!
                </h2>
                <p className="mt-6 text-lg leading-8 text-white-300">
                Sponsor a Shawl, Prasadam, Mala on Thursday -
                </p>
                <p className="mt-6 text-lg leading-8 text-white-300">
                    <a
                        href="https://www.signupgenius.com/go/10C0A4EA4A92BA2FDC16-52046656-sponsor#/"
                        className="text-sm font-semibold leading-6 text-white"
                    >Click to Signup</a>
                </p>
                <h2 className="text-lg font-bold tracking-tight text-white sm:text-4xl">
                    Ram Navmi 2025!
                </h2>
                <p className="mt-6 text-lg leading-8 text-white-300">
                05 April, Saturday -
                </p>
                <p className="mt-6 text-lg leading-8 text-white-300">
                    <a
                        href="https://www.saiparivarfoundation.org/images/RamNavami/RamNavmi-2025.pdf"
                        className="text-sm font-semibold leading-6 text-white"
                    >Click for details</a>
                </p>
            </div>
        </div>
        </div>
    )
}

export default Festivals